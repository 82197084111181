var render = function render(){
  var _vm$currentBranch, _vm$currentBranch2, _vm$currentBranch3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "cash-sales-report"
    }
  }, [_c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.opened_category
    },
    on: {
      "add-categories": _vm.setOpenedCategory
    }
  }), _c('div', {
    staticClass: "filters-row"
  }, [_c('div', {
    staticClass: "filter-item"
  }, [_c('work-period-analytics')], 1), _c('div', {
    staticClass: "filter-item",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "categories-select"
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.categoriesCount))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })])]), (_vm$currentBranch = _vm.currentBranch) !== null && _vm$currentBranch !== void 0 && _vm$currentBranch.branches ? _c('div', {
    staticClass: "filter-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.branches,
      "options": (_vm$currentBranch2 = _vm.currentBranch) === null || _vm$currentBranch2 === void 0 ? void 0 : _vm$currentBranch2.branches,
      "multiple": "",
      "max_selected_count": 1,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": (_vm$currentBranch3 = _vm.currentBranch) === null || _vm$currentBranch3 === void 0 ? void 0 : _vm$currentBranch3.name,
      "select_name": "branch_сlassif"
    },
    on: {
      "input": _vm.setBranches
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }], null, false, 1931744915)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "id": "storage",
      "placeholder": "Все места хранения",
      "multiple": "",
      "filterable": ""
    },
    model: {
      value: _vm.selectedStorage,
      callback: function ($$v) {
        _vm.selectedStorage = $$v;
      },
      expression: "selectedStorage"
    }
  }, _vm._l(_vm.storage_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Все поставщики",
      "multiple": ""
    },
    model: {
      value: _vm.selectedSupplier,
      callback: function ($$v) {
        _vm.selectedSupplier = $$v;
      },
      expression: "selectedSupplier"
    }
  }, _vm._l(_vm.suppliers, function (option) {
    return _c('i-option', {
      key: option.id,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "clear-text",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" Сбросить ")])]), _c('sales-analysis-period', {
    attrs: {
      "period": _vm.dateRange.date
    }
  }), _c('div', {
    staticClass: "analytics-header-row"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "analytics-table-header"
  }, [_c('div', {
    staticClass: "analytics-table-filter"
  }, [_c('analytics-product-search', {
    attrs: {
      "products": _vm.products
    },
    on: {
      "input": _vm.setProducts
    }
  }), _c('group-by', {
    staticClass: "ml-3 mr-3",
    attrs: {
      "type": "sales"
    }
  }), _c('div', {
    staticClass: "d-flex ml-auto"
  }, [_c('e-button', {
    staticClass: "mr-3",
    staticStyle: {
      "height": "32px"
    },
    on: {
      "click": _vm.downloadExel
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      "src": "/img/icons/download.svg",
      "alt": "download"
    }
  }), _vm._v("Скачать ")]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.sort-table-modal",
      modifiers: {
        "sort-table-modal": true
      }
    }]
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1)], 1)]), _c('sales-analysis-table', {
    attrs: {
      "is_by_category": _vm.isByCategoryValue,
      "period": _vm.data_range,
      "pagination": _vm.marginTable.pagination,
      "analytics": _vm.marginTable.data,
      "loading_category": _vm.loading_category,
      "busy": _vm.loading,
      "fields": _vm.fields,
      "table_name": "sales-anal;ыаывd"
    },
    on: {
      "categoryChange": _vm.opened_category_change,
      "change_sort": _vm.change_sort
    }
  })], 1)], 1)], 1), _c('pagination', {
    attrs: {
      "total": _vm.marginTable.items_total,
      "table_name": "sales-analytics",
      "default_take": 50
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }